/* Import the Solitreo font */
@import url('https://fonts.googleapis.com/css2?family=Solitreo&display=swap');

/* General Container Styling */
.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  flex-direction: column;
  background: url('./components/FireflySukkah.jpg') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  z-index: 1;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
}

.container > * {
  position: relative;
  z-index: 2;
}

/* General Container Styling */
.container.with-announcement {
  padding-top: 120px; /* Adjust based on the combined height of the announcement bar and header */
}

/* Header Styling */
.header {
  width: 100%;
  height: 65px;
  padding: 0 20px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7));
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: margin-top 0.3s ease; /* Smooth transition */
}

.header.with-announcement {
  margin-top: 42px; /* Adjust based on the height of the announcement bar */
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.header-content h1 {
  margin: 0;
  flex: 1;
  font-size: 24px;
  color: #333;
  font-weight: 600;
  text-align: center;
  font-family: "Lucida Console";
}

/* Hamburger Menu Styling */
.hamburger {
  width: 35px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: absolute;
  left: 20px;
}

.bar {
  height: 3px;
  width: 100%;
  background-color: #333;
  border-radius: 2px;
}

/* Unified Progress Bar and Card Container */
.step-container {
  width: 100%;
  max-width: 800px;
  padding: 25px 15px; /* 25px top/bottom, 15px left/right */
  margin-top: 60px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.7));
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  opacity: 1;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out, padding 0.6s ease-in-out, height 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

/* Transition classes for step change */
.step-container.exiting {
  opacity: 0;
  transform: translateX(-20px); /* Move slightly left during exit */
}

.step-container.entering {
  opacity: 0;
  transform: translateX(20px); /* Move slightly right during entry */
}

.step-container.active {
  opacity: 1;
  transform: translateX(0); /* Reset position for active step */
}

/* Smooth transitions for the step container and content */
.step-container {
  transition: all 0.6s ease-in-out;
}

.step-content {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

.step-container.step-1 .step-content,
.step-container.step-2 .step-content,
.step-container.step-3 .step-content,
.step-container.step-4 .step-content,
.step-container.step-5 .step-content {
  opacity: 1;
  transform: translateY(0);
}

/* Card Container */
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 20fr));
  gap: 25px;
  width: 100%;
}

.step-card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 16px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step-card:active {
  transform: scale(0.95);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.step-card img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
}

.step-card p {
  padding: 10px 15px;
  font-size: 18px;
  color: #333333;
}

/* Contact Info Styling */
.contact-info {
  width: 80%; /* The form will now take up 90% of the available space */
  max-width: 1200px; /* But it will not exceed 1200px */
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  color: #444;
}

/* Input Icon */
.input-icon {
  position: relative;
}

.input-icon input {
  padding-left: 35px;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
}

.input-icon svg {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

.input-icon input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Invalid Feedback */
.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}

/* Button Styling */
button.btn {
  width: 60%;
  height: 45px;
  border: none;
  border-radius: 6px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block;
  margin: 0 auto;
}

button.btn:hover {
  background-color: #0056b3;
}

/* Step Navigation */
.step-count {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}

/* Responsive Adjustments */
@media (max-width: 900px) {
  .step-container {
    width: 90%;
  }
}

.input-icon {
  position: relative;
}

.input-icon input {
  padding-left: 35px;
}

.input-icon svg {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}


/* Welcome Message Styling */
.landing {
  text-align: center;
  margin-bottom: 20px;
}

.landing h1 {
  font-size: 28px;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  margin-bottom: 30px;
}

/* Hide the step container when on the welcome screen */
.step-container.hidden {
  display: none;
}

/* Start CTA Styling */
.start-cta {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.start-cta .btn {
  padding: 15px 40px; /* Adjust padding */
  font-size: 20px; /* Increased font size for better readability */
  font-weight: bold;
  color: #333; /* Darker text color for contrast */
  background: #ffffff; /* Light background color */
  border: 3px solid #285723;
  border-radius: 32px; /* Slightly larger border radius for a more prominent button */
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}

.start-cta .btn:hover {
  background: #dddddd; /* Slightly darker shade on hover */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.start-cta .btn:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.step-card.other-option {
  background-color: #ffffff; /* Different background for "Other" */
  /*font-weight: bold;*/
}

.step-card.other-option p {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  padding: 20px 40px;
}

.small-text {
  font-size: 12px; /* Smaller text for the priced upon request */
  color: #888; /* Subtle color for the small text */
  font-weight: normal;
}
/* Other existing CSS remains unchanged */

/*.team-reach-out {*/
/*  margin-bottom: 20px;  !* Space between the message and the summary content *!*/
/*  font-size: 14px;*/
/*  color: #666;*/
/*  text-align: center;  !* Center-align the message *!*/
/*}*/

/* Site Name Styling */
.site-name {
  font-family: 'Solitreo', cursive !important;
  font-size: 28px; /* Adjust font size as needed */
  margin: 0;
  color: #333; /* Adjust text color if necessary */

}

.site-name .hebrew {
  font-family: 'Solitreo', cursive !important;
  font-size: 2.3rem;
  /*margin-right: 8px;*/
  color: #000;
  font-weight: normal !important;
}

.site-name .hebrewd {
  font-family: 'Solitreo', cursive !important;
  font-size: 2rem;
  /*margin-right: 8px;*/
  color: #000;
  font-weight: bold !important;
}

.site-name .hebrewh {
  font-family: 'Solitreo', cursive !important;
  font-size: 1.55rem;
  /*margin-right: 8px;*/
  color: #000;
  font-weight: bold !important;
}

.site-name .english {
  font-family: 'Solitreo', cursive !important;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}


