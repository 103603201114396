/* Full-Page Layout */
.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 65px 10px 40px; /* Reduced padding to bring cards closer to header */
    min-height: 100vh;
    position: relative;
}

/* Dynamic Main Card Styling for Form */
.contact-card {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.7));
    padding: 15px 30px; /* Further reduced top padding to bring title closer */
    border-radius: 16px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: calc(100% - 40px); /* Ensures even spacing on both sides */
    text-align: left;
    margin-bottom: 40px;
    transition: box-shadow 0.3s ease;
    z-index: 2;
}

.contact-card:hover {
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2);
}

.contact-card h3 {
    font-size: 32px;
    color: #2c3e50; /* Dark neutral for contrast */
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #14320e; /* Warm accent color */
    font-weight: 700;
}

/* Form Styling */
.contact-form .form-group {
    margin-bottom: 20px;
    position: relative;
}

.contact-form label {
    font-size: 18px;
    color: #2c3e50; /* Dark neutral */
    margin-bottom: 8px;
    display: block;
    font-weight: 600;
}

contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 14px; /* Shortened padding to reduce input height */
    border-radius: 8px;
    border: 1px solid #bdc3c7;
    font-size: 16px;
    color: #2c3e50;
    background-color: rgba(255, 255, 255, 0.8);
    outline: none;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: #14320e;
    box-shadow: 0 0 8px rgba(230, 126, 34, 0.25);
}

.contact-form textarea {
    min-height: 140px; /* Shortened height to reduce overall card length */
    resize: vertical;
}

.contact-form button {
    padding: 16px 24px; /* Adjusted padding to reduce button height */
    border: none;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.73);
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form button:hover {
    background-color: #14320e;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

/* Input Icon Styling */
.input-icon {
    position: relative;
}

.input-icon input {
    padding-left: 50px;
}

.input-icon svg {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #14320e;
    font-size: 20px;
}

/* Floating Business Card-Style Contact Info */
.business-card {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.7));
    padding: 15px 30px; /* Further reduced top padding to bring title closer */
    border-radius: 16px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: calc(100% - 40px); /* Ensures even spacing on both sides */
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 1;
}

.business-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2);
}

.business-card p {
    margin: 10px 0;
    font-size: 18px;
    color: #2c3e50; /* Dark neutral text */
    font-weight: 600;
}

.business-card a {
    color: #388a27; /* Warm accent link color */
    text-decoration: none;
    font-weight: 700;
    font-size: 18px;
    transition: color 0.3s ease;
}

.business-card a:hover {
    color: #004ad3; /* Darker on hover */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .contact-card,
    .business-card {
        width: calc(100% - 40px); /* Ensures even spacing on both sides */
    }

    .contact-card h3 {
        font-size: 28px;
    }

    .business-card p {
        font-size: 16px;
    }
}

/* Consistent Header Styling */
.business-card h3 {
    font-size: 28px;
    color: #2c3e50; /* Matches the form header color */
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #020202; /* Matches the form header border */
    font-weight: 700;
}
/* Confirmation dialog styles */
.confirmation-message {
    background-color: rgba(0, 123, 255, 0.9); /* Solid background color with slight transparency */
    color: white; /* White text for better contrast */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin-top: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add a shadow for a floating effect */
    z-index: 10; /* Ensure the message is on top of everything */
    position: relative;
}

/* Full-screen modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Dark overlay with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure the modal is on top of everything */
}

/* Modal content centered on the screen */
.modal-content {
    background-color: #ffffff; /* White background */
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 80%;
    max-width: 500px;
}

.modal-content h4 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    font-weight: bold;
    color: #333333; /* Dark text for contrast */
}

.modal-content p {
    font-size: 1.2rem;
    color: #666666; /* Slightly lighter text */
}

/* Hide the form while the modal is active */
.form-hidden {
    visibility: hidden;
    opacity: 0.5; /* Dim the form */
}
