/* Premium Rentals Page Styles */
.premium-rentals-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Push content towards the top */
    align-items: center;
    height: 80vh; /* More height to center content properly */
    padding-top: 100px; /* Add some padding at the top to raise content */
    text-align: center;
}

.premium-rentals-header {
    max-width: 800px;
    margin: 0 auto;
}

.premium-rentals-header h1 {
    color: #ffffff; /* White text for the header */
    font-size: 2.5rem;
    margin-bottom: 40px;
}

.premium-rentals-header p {
    color: #ffffff; /* White text for the description */
    font-size: 1.6rem;
    margin-bottom: 30px;
}

.cta-button {
    padding: 15px 40px; /* Adjust padding */
    font-size: 20px; /* Increased font size for better readability */
    font-weight: bold;
    color: #333; /* Darker text color for contrast */
    background: #ffffff; /* Light background color */
    border: 3px solid #285723;
    border-radius: 32px; /* Slightly larger border radius for a more prominent button */
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
    align-items: center;
    justify-content: center;
    width: auto;
}

.cta-button:hover {
    background-color: #285723; /* Darker green on hover */
    color: #ffffff; /* White text color on hover */
}

/* Optional: Control the page background */
.premium-rentals-page {
    /*background-color: #f8f9fa;*/
}
