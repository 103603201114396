/* Summary Container */
.summary-container {
    padding: 25px;
    background-color: #ffffff;
    border-radius: 12px;
    text-align: left;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    max-width: 700px;
    margin: 0 auto;
}

.summary-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
}

.summary-header h2 {
    font-size: 24px;
    color: #333;
    margin: 0;
}

.summary-price {
    font-size: 30px;
    color: #333;
    font-weight: bold;
}

.summary-details {
    margin-top: 20px;
}

.summary-details h3 {
    font-size: 20px;
    color: #444;
    margin-bottom: 15px;
}

.summary-details ul {
    list-style-type: none;
    padding: 0;
}

.summary-details li {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #555;
    margin-bottom: 12px;
}

.summary-icon {
    margin-right: 12px;
    font-size: 22px;
    color: #777;
}

.summary-details span,
.summary-contact span {
    color: #333;
    margin-right: 8px;
}

.summary-details strong,
.summary-contact strong {
    color: #000;
    font-weight: 600;
}

/* Divider */
.summary-divider {
    margin: 20px 0;
    border-bottom: 1px solid #ddd;
}

/* Contact Information Summary */
.summary-contact {
    margin-top: 20px;
}

.summary-contact h3 {
    font-size: 20px;
    color: #444;
    margin-bottom: 15px;
}

.summary-contact ul {
    list-style-type: none;
    padding: 0;
}

.summary-contact li {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #555;
    margin-bottom: 12px;
}

/* Spinner */
.spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top-color: #333;
    animation: spin 1s ease-in-out infinite;
    margin: 20px auto;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}


