/* Full-Page Layout */
.about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 10px 20px;
    min-height: 100vh;
    background-color: transparent;
    position: relative;
}

/* Hero Section */
.hero-section {
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
}

.hero-section h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
}

.hero-section p {
    font-size: 20px;

}

/* Content Layout */
.about-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    width: 100%;
    max-width: 600px;
}

@media (min-width: 768px) {
    .about-content {
        grid-template-columns: repeat(2, 1fr); /* Two columns on wider screens */
        gap: 30px;
        max-width: 1200px;
        grid-auto-flow: dense; /* Allow items to fill in a masonry-like layout */
    }
}

/* Card Styling */
.about-card {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.7));
    padding: 15px;
    border-radius: 14px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    text-align: left;
}

.about-card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.about-card h3 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #2c3e50;
    border-bottom: 2px solid #14320e;
    padding-bottom: 5px;
}

.about-card p {
    font-size: 16px;
    line-height: 1.6;
    color: #2c3e50;
}

/* Specific Card Sizes and Positions */
.journey-card {
    grid-column: span 2; /* Full width on larger screens */
}

.mission-card {
    grid-column: span 2; /* Full width on larger screens */
    order: 5; /* Ensure it's second-to-bottom */
}

.testimonials-card {
    grid-column: span 1; /* Single column on larger screens */
    grid-row: span 2; /* Spanning two rows */
}

.gallery-card {
    grid-column: span 2; /* Full width on larger screens */
    grid-row: span 2; /* Spanning two rows */
}

/* Twitter Feed Full Row on Mobile */
.twitter-full-row {
    grid-column: 1 / -1;
    width: 100%;
}

/* Improved Testimonial Styling */
.testimonials-card .testimonial {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.testimonials-card blockquote {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 10px;
    color: #2c3e50;
    border-left: 4px solid #14320e;
    padding-left: 10px;
}

/* Gallery Section */
.gallery-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.gallery-grid img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

/* Call to Action Section */
.cta-card {
    text-align: center;
    grid-column: span 2;
}

.cta-button {
    padding: 10px 20px;
    background-color: #14320e;
    color: #fff;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #0f270b;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
    .hero-section h1 {
        font-size: 32px;
    }

    .hero-section p {
        font-size: 18px;
    }

    .about-card {
        padding: 20px;
        border-radius: 14px;
    }

    .about-card h3 {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .about-card p {
        font-size: 16px;
    }

    .gallery-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .cta-button {
        padding: 15px 30px;
        font-size: 18px;
    }
}

/* Mobile Adjustments - No gaps and mission to 2nd last */
@media (max-width: 767px) {
    .about-content {
        grid-template-columns: 1fr; /* Single column for mobile */
    }

    .about-card {
        grid-column: 1 / -1; /* Make sure cards fill the row */
    }

    .mission-card {
        order: 5; /* Second-to-bottom on mobile */
    }
}
