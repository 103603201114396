.hamburger {
    width: 35px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 1000;
}

.bar {
    height: 3px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 2px;
}

.menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 275px;
    height: 0;  /* Start with zero height */
    background: url('./rail.jpg') no-repeat bottom,
    linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5));
    background-size: cover;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.5);
    transition: height 1s ease-in-out;
    z-index: 2300;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 20px;
}

.menu.open {
    height: 100%;  /* Unroll to full height */
}

.menu-content {
    display: flex;
    flex-direction: column;
    gap: 15px;  /* Reduce space between menu items */
    padding-top: 70px;  /* Keep the top padding reasonable */
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    font-size: 36px;  /* Slightly larger close button */
    cursor: pointer;
}

.menu a {
    display: flex;
    align-items: center;
    padding: 20px 0;  /* Increase padding for larger items */
    text-decoration: none;
    color: #ffffff;
    font-size: 24px;  /* Increase font size for larger items */
    font-weight: bold;
    transition: color 0.3s ease, background-color 0.3s ease;
}

.menu a svg {
    margin-right: 15px;  /* Keep space between icon and text */
    color: #ffffff;
    font-size: 28px;  /* Slightly larger icons */
}

.menu a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    padding-left: 15px;
    border-radius: 8px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1400;
}
