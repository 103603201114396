.announcement-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(90deg, #0a45da, #426cf4, #75a9ff, #b3ceff); /* Balanced gradient with a mix of deeper and lighter blues */
    color: #ffffff;
    text-align: center;
    padding: 10px 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    z-index: 1200;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-wrap: wrap; /* Allow content to wrap */
}

.announcement-bar .announcement-text {
    font-size: 16px;
    line-height: 1.5;
    margin-right: 8px;
}

.announcement-bar .learn-more-link {
    color: #ffffff;
    text-decoration: underline;
    margin-left: 4px;
    font-weight: bold;
}

.announcement-bar .learn-more-link:hover {
    color: rgba(251, 237, 58, 0.7); /* Change color on hover */
}

.announcement-bar .flag-emoji {
    font-size: 18px;
    margin-left: 1px;
    animation: wave 2s infinite;
    display: inline-block;
}

.announcement-bar .close-btn {
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
    margin-left: 15px;
    transition: color 0.3s ease;
}

.announcement-bar .close-btn:hover {
    color: #ffeb3b;
}

@keyframes wave {
    0% { transform: rotate(0deg); }
    10% { transform: rotate(14deg); }
    20% { transform: rotate(-8deg); }
    30% { transform: rotate(14deg); }
    40% { transform: rotate(-4deg); }
    50% { transform: rotate(10.0deg); }
    60% { transform: rotate(0deg); }
    100% { transform: rotate(0deg); }
}

@media (max-width: 780px) {
    .announcement-bar {
        flex-direction: column;
        padding: 5px 10px;
    }

    .announcement-bar .announcement-text {
        font-size: 16px;
        margin-bottom: 5px;
        text-align: center;
    }

    .announcement-bar .close-btn {
        margin-top: -2px;
    }
}

@media (max-width: 750px) {
    .announcement-bar {
        flex-direction: column;
        padding: 5px 10px;
    }

    .announcement-bar .announcement-text {
        font-size: 12px;
        margin-bottom: 5px;
        text-align: center;
    }

    .announcement-bar .close-btn {
        margin-top: -2px;
    }
}

@media (max-width: 390px) {
    .announcement-bar {
        flex-direction: column;
        padding: 5px 10px;
    }

    .announcement-bar .announcement-text {
        font-size: 10.5px;
        margin-bottom: 5px;
        text-align: center;
    }

    .announcement-bar .close-btn {
        margin-top: -2px;
    }
}
