/* Progress Bar */
.progress-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 10px 0;
    position: relative;
    align-items: center;
    z-index: 2;
}

.progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    position: relative;
    cursor: pointer;
    transition: opacity 0.3s, color 0.3s ease-in-out;
}

.progress-step.completed {
    opacity: 1;
    color: #2ecc71;
    transition: color 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.progress-step.completed::after {
    content: '✔️';
    position: absolute;
    top: 10%;
    left: 75%;
    transform: translate(-50%, -50%) scale(0);
    font-size: 18px;
    color: #2ecc71;
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.progress-step.completed .step-icon-container {
    border-color: transparent;
    transform: scale(1.1);
    z-index: 2; /* Ensure icons stay above the progress bar */
}

.progress-step.active .step-icon-container {
    border-color: #3498db;
    box-shadow: 0 0 15px rgba(52, 152, 219, 0.5);
    animation: bounce 0.5s ease-in-out;
    z-index: 2; /* Ensure active icon stays above the progress bar */
}

.step-icon {
    font-size: 26px;
    margin: 0;
    position: relative;
    top: 0;
    transition: transform 0.3s ease-in-out;
    z-index: 3; /* Ensure the icon is above everything */
}

.progress-step.active .step-icon {
    font-size: 31px;
    transform: scale(1.4);
    color: #3498db;
}

.step-title {
    font-size: 12px;
    color: #666;
    margin-top: 5px;
    transition: color 0.3s ease;
}

.progress-step.active .step-title {
    color: #3498db;
}

.progress-step.completed .step-icon {
    color: #2ecc71;
}

.progress-step:before {
    content: '';
    position: absolute;
    top: 40%; /* Bar height within container */
    left: -50%;
    width: 0%; /* Start with 0 width for transition */
    height: 3px; /* Bar thickness */
    background-color: #ccc;
    z-index: 1; /* Lower z-index to keep the line behind the icons */
    transform: translateY(-50%);
    transition: width 0.5s ease-in-out, background-color 0.5s ease-in-out;
}

.progress-step.completed:before {
    background-color: #2ecc71;
    width: 100%;
    transition: width 0.5s ease-in-out;
}

.progress-step.active:before {
    background-color: transparent;
    border-top: 3px dashed #2ecc71; /* Consistent height for dashed line */
    height: 3.5px;
    width: 100%;
    top: 40%;
    transform: translateY(-50%);
    transition: border-top-width 0.5s ease-in-out, width 0.5s ease-in-out;
}

.progress-step:first-child:before {
    display: none;
}

.progress-step.active {
    opacity: 1;
}

.progress-step.future {
    opacity: 0.3;
    pointer-events: none;
}

.progress-step.future .step-icon-container {
    border-color: transparent;
}

.progress-step.future .step-icon {
    color: #666;
}

/* Keyframes for Bounce Animation */
@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}

/* Animation for checkmark */
.progress-step.completed::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
}

.progress-step .step-icon-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color 0.3s, box-shadow 0.3s;
    z-index: 2; /* Ensure the icon stays above the progress bar */
}

.progress-step.active .step-icon-container {
    border-color: #3498db; /* The color of the circle border */
    box-shadow: 0 0 15px rgba(52, 152, 219, 0.5); /* Blue glow effect around active step */
}

.progress-step.completed .step-icon-container {
    border-color: transparent; /* Hide border for completed steps */
}

.progress-step.future .step-icon-container {
    border-color: transparent; /* Hide border for future steps */
}
